<template>
  <v-container fluid>
    <div class="">
      <v-flex class="py-2 px-3 tableHeader">Companies</v-flex>
    </div>
    <v-data-table
      item-key="id"
      height="530"
      class="elevation-1 data-table"
      :headers="tableHeader"
      :items="companiesList"
      :loading="loading"
      :options="paginationOptions"
      :server-items-length="totalCount"
      loading-text="Loading Please Wait"
      no-results-text="No Data found...!"
      no-data-text="No Data available"
      @pagination="paginationChanges"
      @click:row="rowClicked"
    >
      <template v-slot:[`item.id`]="{ item }">
        <v-btn icon class="secondary mr-3">
          <v-icon color="#fff" @click="editCompany(item)">mdi-pencil</v-icon>
        </v-btn>

        <v-btn icon class="error">
          <v-icon color="#fff" @click="deleteCompany(item.id)"
            >mdi-delete</v-icon
          >
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" maxWidth="1200px">
      <AddCompany
        :key="dialog"
        :edit="edit"
        :companyDetail="companyDetail"
        @close-model="closeModal"
        @refresh="fetchCompanies"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { UTC_TO_LOCAL } from "@/helpers";
import AddCompany from "@/components/system-admin/add-company";
export default {
  name: "system-admin-dashboard",
  components: { AddCompany },
  data() {
    return {
      companies: [],
      loading: false,
      totalCount: 0,
      currentPage: 1,
      limit: 10,
      dialog: false,
      edit: false,
      companyDetail: "",
    };
  },
  computed: {
    paginationOptions() {
      return { page: this.currentPage, itemsPerPage: this.limit };
    },
    tableHeader() {
      return [
        {
          text: "Company Name",
          align: "start",
          value: "name",
        },
        {
          text: "Contract Date",
          align: "start",
          value: "contract_date",
        },
        {
          text: "Contact Email",
          value: "email",
        },
        {
          text: "Contact Number",
          value: "contact",
        },
        {
          text: "Portal URL",
          value: "url",
        },
        {
          text: "Address",
          value: "address",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.action"),
          value: "id",
          width: "11%",
        },
      ];
    },
    companiesList() {
      return this.companies.map((company) => {
        const {
          name,
          created_at,
          _id,
          contact,
          email,
          address,
          url,
          advanceDashboard,
          calendar_notifications,
          emails,
          evaluations,
          leaderboard,
          search,
        } = company;
        return {
          id: _id,
          name,
          contract_date: UTC_TO_LOCAL(created_at, "lll"),
          contact,
          email,
          address,
          url,
          advanceDashboard,
          calendar_notifications,
          emails,
          evaluations,
          leaderboard,
          search,
        };
      });
    },
  },
  methods: {
    rowClicked(data) {
      console.log("SOME ROW CLICKED", data);
    },
    paginationChanges(options) {
      const { page, itemsPerPage } = options;
      if (page !== this.currentPage || itemsPerPage !== this.limit) {
        this.currentPage = page;
        this.limit = itemsPerPage;
        this.fetchCompanies();
      }
    },
    async deleteCompany(id) {
      try {
        let response = await this.axios.delete(`/company/${id}`);
        if (response.status === 200) {
          this.$notify({
            group: "success",
            type: "success",
            title: "Success",
            text: "Company deleted successfully!",
          });
          this.fetchCompanies();
        } else {
          this.$notify({
            group: "backend-error",
            type: "error",
            title: "Validation Error",
            text: "Something went wrong. Please try again later.",
          });
        }
      } catch (error) {
        this.$notify({
          group: "backend-error",
          type: "error",
          title: "Validation Error",
          text: "Something went wrong. Please try again later.",
        });
        // this.error_alert = true;
        // this.error_message = "Something went wrong ";
      }
    },
    async fetchCompanies() {
      try {
        this.loading = true;
        const { currentPage, limit } = this;
        const resp = await this.axios.get(
          `/company?p=${currentPage}&limit=${limit}`
        );
        const { data, count } = resp.data;
        this.companies = data;
        this.totalCount = count;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    closeModal() {
      this.dialog = false;
      (this.edit = false), (this.companyDetail = "");
    },
    editCompany(detail) {
      this.edit = true;
      this.companyDetail = detail;
      this.dialog = true;
    },
  },
  created() {
    this.fetchCompanies();
  },
};
</script>

<style lang="scss" scoped>
.tableHeader {
  letter-spacing: 1px;
  font-weight: bold;
  background-color: #eaeef2;
  border: 1px solid #e6e6f1;
  color: #3dade5;
}
.tableSearch {
  background-color: #f7f7f7;
  border: 1px solid #e6e6f1;
  min-height: 70px;
}
.table-row {
  background-color: #f7f7f7;
  border: 1px solid #e6e6f1;
  td {
    color: #2f3242;
  }
}

.data-table {
  &::v-deep .v-data-table-header__icon {
    display: none;
  }
}
</style>
